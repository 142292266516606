import React, { useEffect, useState } from "react";
import axios from "axios";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { styled } from "@mui/material/styles";
import { Box, CircularProgress, Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import PredictMatchBox from "./components/PredictMatchBox";
import CarouselWinner from "./components/Carousel";
import MobileCarouselWinner from "./components/MobileCarousel";
import Matches from "./components/Matches";
import TodayCompletedMatch from "./components/TodayCompletedMatch.jsx";
import Footer from "./components/Footer";
import PredicPopUp from "./components/PredicPopUp";
import PredictGoalPopUp from "./components/PredictGoalPopup";

const StyledProductImg = styled("img")({
  top: 0,
  width: "100%",
  height: "100%",
  objectFit: "cover",
  position: "absolute",
});

export default function Main() {
  const [todaysMatchs, setTodaysMatchs] = useState([]);
  const [todaysMatchLoading, setTodaysMatchLoading] = useState(true);
  const [latestWinners, setLatestWinners] = useState([]);
  const [latestWinnersLoading, setLatestWinnersLoading] = useState(true);
  const [widthPercentage, setWidthPercentage] = useState(0);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [selectedMatch, setSelectedMatch] = useState({});
  // console.log("re rendering main.js");

  const handleClickOpen = (e, item) => {
    if (item.matchType === "GROUP") {
      setOpen(true);
      setSelectedMatch(item);
    } else {
      setOpen1(true);
      setSelectedMatch(item);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setOpen1(false);
    getMatch();
  };

  const xlsize = useMediaQuery("(min-width:1500px)");
  const lgsize = useMediaQuery("(min-width:1200px )");
  const mdsize = useMediaQuery("(min-width:900px )");
  const smsize = useMediaQuery("(min-width:600px )");
  const xssize = useMediaQuery("(max-width:600px)");

  const getMatch = () => {
    setTodaysMatchLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/match/user/today`)
      .then((res) => {
        // console.log("todays match", res);
        setTodaysMatchs(res.data.data.records);
        setTodaysMatchLoading(false);
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const getLatestWinners = () => {
    setLatestWinnersLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/draw/user/latest`)
      .then((res) => {
        // console.log("latest winners", res);
        setLatestWinners(res.data.data.winners);
        setLatestWinnersLoading(false);
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  useEffect(() => {
    getMatch();
    getLatestWinners();
  }, []);
  useEffect(() => {
    if (xssize) {
      setWidthPercentage(65);
    }
    if (smsize && !mdsize) {
      setWidthPercentage(40);
    }
    if (mdsize && !lgsize) {
      setWidthPercentage(30);
    }
    if (lgsize && !xlsize) {
      setWidthPercentage(20);
    }
    if (xlsize) {
      setWidthPercentage(15);
    }
  }, [xssize, smsize, mdsize, lgsize, xlsize]);

  return (
    <>
      <Box>
        <Grid>
          {/* predict popup */}
          {open && <PredicPopUp handleClose={handleClose} selectedMatch={selectedMatch} />}
          {open1 && <PredictGoalPopUp handleClose={handleClose} selectedMatch={selectedMatch} />}
          {/* predict popup ends */}

          {lgsize && (
            <Grid item xs={12}>
              <Box sx={{ position: "relative", pt: "35%" }}>
                <Box
                  sx={{
                    zIndex: 9,
                    transform: " translate(-50%, -50%)",
                    top: "50%",
                    left: "50%",
                    position: "absolute",
                    textTransform: "uppercase",
                    textAlign: "center",
                    width: "900px",
                  }}
                >
                  <img
                    style={{ height: "180px", objectFit: "fit", width: "150px" }}
                    src="static/images/logo.png"
                    alt="logo"
                  />
                  <Typography
                    sx={{
                      color: "white",
                      fontSize: "40px",
                      fontWeight: "800",
                      textShadow: "-1px -1px 0px #092347, 1px 1px 0px #092347, 2px 2px 0px #092347",
                    }}
                  >
                    WC Mania Prediction Challenge
                  </Typography>
                  <Typography sx={{ color: "white", fontSize: "25px" }}>
                    PREDICT MATCHES FOR FREE
                  </Typography>
                </Box>
                {/* <Stack
                  sx={{
                    zIndex: 99,
                    position: "absolute",
                    width: "200px",
                    height: "40px",
                    right: "40px",
                    top: "47px",
                    border: "1px solid #FFFFFF",
                    borderRadius: "18px",
                  }}
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  spacing={0.5}
                >
                  <Stack
                    sx={{
                      borderRadius: "50%",
                      height: "24px",
                      width: "24px",
                      textAlign: "center",
                      backgroundImage: 'url("/static/images/whatsapp.jpg")',
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      margin: 0,
                      cursor: "pointer",
                    }}
                  />
                  <Typography sx={{ color: "white", fontSize: "14px" }}>+ 91 9072863636</Typography>
                </Stack> */}
                {/* <Stack
                  sx={{
                    zIndex: 99,
                    position: "absolute",
                    right: "-70px",
                    bottom: "157px",
                    transform: "rotate(-90deg)",
                  }}
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  spacing={0.5}
                >
                  <Stack
                    sx={{
                      borderRadius: "50%",
                      height: "24px",
                      width: "24px",
                      textAlign: "center",
                      backgroundImage: 'url("/static/images/whatsapp.jpg")',
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      margin: 0,
                      cursor: "pointer",
                    }}
                  />
                  <Typography sx={{ color: "white", fontSize: "14px", marginRight: "10px" }}>
                    + 91 9072863636
                  </Typography>
                  <Box sx={{ height: "1px", width: "116px", bgcolor: "white" }} />
                </Stack> */}
                <StyledProductImg alt="Banner" src="static/images/banner.jpg" />
              </Box>
            </Grid>
          )}
          {mdsize && !lgsize && (
            <Grid item xs={12}>
              <Box sx={{ position: "relative", pt: "40%" }}>
                <Box
                  sx={{
                    zIndex: 9,
                    transform: " translate(-50%, -50%)",
                    top: "50%",
                    left: "50%",
                    position: "absolute",
                    textTransform: "uppercase",
                    textAlign: "center",
                    width: "750px",
                  }}
                >
                  <img
                    style={{ height: "130px", objectFit: "fit", width: "100px" }}
                    src="static/images/logo.png"
                    alt="logo"
                  />
                  <Typography
                    sx={{
                      color: "white",
                      fontSize: "35px",
                      fontWeight: "700",
                      textShadow: "-1px -1px 0px #092347, 1px 1px 0px #092347, 2px 2px 0px #092347",
                    }}
                  >
                    WC Mania Prediction Challenge
                  </Typography>
                  <Typography sx={{ color: "white", fontSize: "20px" }}>
                    PREDICT MATCHES FOR FREE
                  </Typography>
                </Box>
                {/* <Stack
                  sx={{
                    zIndex: 99,
                    position: "absolute",
                    width: "170px",
                    height: "36px",
                    right: "40px",
                    top: "47px",
                    border: "1px solid #FFFFFF",
                    borderRadius: "18px",
                  }}
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  spacing={0.5}
                >
                  <Stack
                    sx={{
                      borderRadius: "50%",
                      height: "20px",
                      width: "20px",
                      textAlign: "center",
                      backgroundImage: 'url("/static/images/whatsapp.jpg")',
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      margin: 0,
                      cursor: "pointer",
                    }}
                  />
                  <Box sx={{ color: "white", fontSize: "12px" }}>+ 91 9072863636</Box>
                </Stack> */}
                {/* <Stack
                  sx={{
                    zIndex: 99,
                    position: "absolute",
                    right: "-70px",
                    bottom: "127px",
                    transform: "rotate(-90deg)",
                  }}
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  spacing={0.5}
                >
                  <Stack
                    sx={{
                      borderRadius: "50%",
                      height: "20px",
                      width: "20px",
                      textAlign: "center",
                      backgroundImage: 'url("/static/images/whatsapp.jpg")',
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      margin: 0,
                      cursor: "pointer",
                    }}
                  />
                  <Box sx={{ color: "white", fontSize: "12px", marginRight: "10px" }}>
                    + 91 9072863636
                  </Box>
                  <Box sx={{ height: "1px", width: "100px", bgcolor: "white" }} />
                </Stack> */}
                <StyledProductImg alt="Banner" src="static/images/banner.jpg" />
              </Box>
            </Grid>
          )}
          {smsize && !mdsize && (
            <Grid item xs={12}>
              <Box sx={{ position: "relative", pt: "40%" }}>
                <Box
                  sx={{
                    zIndex: 9,
                    transform: " translate(-50%, -50%)",
                    top: "50%",
                    left: "50%",
                    position: "absolute",
                    textTransform: "uppercase",
                    textAlign: "center",
                  }}
                >
                  <img
                    style={{ height: "110px", objectFit: "fit", width: "90px" }}
                    src="static/images/logo.png"
                    alt="logo"
                  />
                  <Typography
                    variant="h1"
                    sx={{
                      color: "white",
                      fontSize: "25px",
                      fontWeight: "700",
                      textShadow: "-1px -1px 0px #092347, 1px 1px 0px #092347, 2px 2px 0px #092347",
                    }}
                  >
                    WC Mania Prediction Challenge
                  </Typography>
                  <Typography sx={{ color: "white", fontSize: "18px" }}>
                    PREDICT MATCHES FOR FREE
                  </Typography>
                </Box>
                {/* <Stack
                  sx={{
                    zIndex: 99,
                    position: "absolute",
                    width: "170px",
                    height: "32px",
                    right: "20px",
                    top: "20px",
                    border: "1px solid #FFFFFF",
                    borderRadius: "18px",
                  }}
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  spacing={0.5}
                >
                  <Stack
                    sx={{
                      borderRadius: "50%",
                      height: "20px",
                      width: "20px",
                      textAlign: "center",
                      backgroundImage: 'url("/static/images/whatsapp.jpg")',
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      margin: 0,
                      cursor: "pointer",
                    }}
                  />
                  <Box sx={{ color: "white", fontSize: "12px" }}>+ 91 9072863636</Box>
                </Stack> */}

                <StyledProductImg alt="Banner" src="static/images/banner.jpg" />
              </Box>
            </Grid>
          )}
          {xssize && !smsize && (
            <Grid item xs={12}>
              <Box sx={{ position: "relative", pt: "60%" }}>
                <Box
                  sx={{
                    zIndex: 9,
                    transform: " translate(-50%, -50%)",
                    top: "50%",
                    left: "50%",
                    position: "absolute",
                    textTransform: "uppercase",
                    textAlign: "center",
                    width: "350px",
                  }}
                >
                  <img
                    style={{ height: "70px", objectFit: "fit", width: "60px" }}
                    src="static/images/logo.png"
                    alt="logo"
                  />
                  <Typography
                    variant="h1"
                    sx={{
                      color: "white",
                      fontSize: "20px",
                      fontWeight: "700",
                      textShadow: "-1px -1px 0px #092347, 1px 1px 0px #092347, 1px 1px 0px #092347",
                    }}
                  >
                    WC Mania Prediction Challenge
                  </Typography>
                  <Typography sx={{ color: "white", fontSize: "12px" }}>
                    PREDICT MATCHES FOR FREE
                  </Typography>
                </Box>
                {/* <Stack
                  sx={{
                    zIndex: 99,
                    position: "absolute",
                    width: "120px",
                    height: "25px",
                    right: "10px",
                    top: "10px",
                    border: "1px solid #FFFFFF",
                    borderRadius: "18px",
                  }}
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  spacing={0.5}
                >
                  <Stack
                    sx={{
                      borderRadius: "50%",
                      height: "16px",
                      width: "16px",
                      textAlign: "center",
                      backgroundImage: 'url("/static/images/whatsapp.jpg")',
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      margin: 0,
                      cursor: "pointer",
                    }}
                  />
                  <Box sx={{ color: "white", fontSize: "10px" }}>+ 91 9072863636</Box>
                </Stack> */}
                <StyledProductImg alt="Banner" src="static/images/banner.jpg" />
              </Box>
            </Grid>
          )}
        </Grid>
        <Grid
          container
          sx={{
            backgroundImage: 'url("/static/images/bg.jpg")',
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            margin: 0,
          }}
        >
          <Grid item xs={12}>
            <Stack justifyContent="center" alignItems="center" direction="row">
              {/* <Box
              sx={{
                width: { lg: "900px", md: "800px", sm: "590px", xs: "100%" },
                height: { sm: "150px", xs: "100px" },
                backgroundImage: 'url("/static/images/wide.jpg")',
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                margin: 0,
              }}
            > */}
              <video
                autoPlay
                muted
                loop
                style={{
                  width:
                    (lgsize && "900px") ||
                    (mdsize && !lgsize && "800px") ||
                    (smsize && !mdsize && "590px") ||
                    (xssize && "100%"),
                  height: xssize ? "120px" : "200px",
                  objectFit: "cover",
                }}
              >
                <source src="/static/images/video.mp4" type="video/mp4" />
              </video>
              {/* </Box> */}
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Box paddingBottom={6}>
              {todaysMatchLoading ? (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "50vh",
                  }}
                >
                  <CircularProgress />
                </div>
              ) : (
                <Grid container>
                  {todaysMatchs.map((item, key) => {
                    return (
                      <Grid item key={key} xs={12}>
                        {item.pollingStatus === "COMPLETED" ? (
                          <Stack direction="row" justifyContent="center" alignItems="center">
                            <TodayCompletedMatch matches={item} />
                          </Stack>
                        ) : (
                          <Stack direction="row" justifyContent="center" alignItems="center">
                            <PredictMatchBox predict={handleClickOpen} matches={item} />
                          </Stack>
                        )}
                      </Grid>
                    );
                  })}
                </Grid>
              )}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Stack direction="column" justifyContent="center" alignItems="center">
              <Stack
                sx={{ width: "100%" }}
                marginBottom={3}
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Stack
                  sx={{
                    border: "solid white 2px",
                    width: "200px",
                    color: "white",
                    borderRadius: "5px",
                  }}
                  justifyContent="center"
                  alignItems="center"
                  padding={0.5}
                >
                  <Typography variant="body2">WINNERS</Typography>
                </Stack>
              </Stack>
            </Stack>
          </Grid>

          {latestWinnersLoading ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "50vh",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <Grid xs={12} padding={2} mb={6}>
              {xssize && (
                <Box>
                  <MobileCarouselWinner width={widthPercentage} winners={latestWinners} />
                </Box>
              )}
              {!xssize && (
                <Box>
                  <CarouselWinner width={widthPercentage} winners={latestWinners} />
                </Box>
              )}
            </Grid>
          )}

          <Grid item xs={12} mb={6}>
            <Stack direction="row" justifyContent="center" alignItems="center">
              <Matches predict={handleClickOpen} />
            </Stack>
          </Grid>
        </Grid>
        <Footer />
      </Box>
    </>
  );
}
