import { Typography } from "@mui/material";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import React from "react";
import { Carousel } from "react-responsive-carousel";
import WinnerCard from "./WinnerCard";

export default ({ winners, width }) => (
  <Carousel
    autoPlay
    infiniteLoop={winners.length > 1}
    centerSlidePercentage={width * 1}
    interval={2000}
    centerMode
    showIndicators={false}
    showArrows={false}
    showThumbs={false}
    showStatus={false}
    swipeable
  >
    {winners.map((item, key) => {
      return <WinnerCard key={key} winner={item} />;
    })}
  </Carousel>
);
