import { Box, Button, Stack, Typography } from "@mui/material";
import React from "react";
import moment from "moment";
import { Icon } from "@iconify/react";
import Flag from "./Flag";

export default function TodayCompletedMatch({ matches }) {
  return (
    <Box marginTop={3} sx={{ width: { lg: "900px", md: "800px", sm: "590px", xs: "375px" } }}>
      <Stack
        sx={{
          background: "linear-gradient(180deg, #1524E0 0%, #2D5AF1 51.04%, #0006D8 100%)",
          borderRadius: "14.5347px 14.5347px 0px 0px",
          color: "white",
        }}
        direction="row"
        justifyContent="center"
        alignItems="center"
        padding={1}
        spacing={2}
      >
        <Typography>{matches?.matchType?.replaceAll("_"," ")}</Typography>
       <Typography> {moment(matches.matchDate).format("ddd, DD MMM h:mm a")}</Typography>
      </Stack>
      <Stack
        direction={{ xl: "row", lg: "row", md: "row", sm: "column", xs: "column" }}
        paddingY={2}
        paddingX={2}
        spacing={3}
        justifyContent="center"
        alignItems="center"
        sx={{
          background: " rgba(43, 85, 239, 0.15)",
          borderRadius: " 0px 0px 14.5347px 14.5347px ",
          color: "white",
        }}
      >
        <Stack
          direction="row"
          justifyContent={{
            xs: "center",
            sm: "space-between",
            md: "space-between",
            lg: "space-between",
          }}
          alignItems="center"
          spacing={{ xl: 3, lg: 3, md: 2, sm: 2, xs: 4 }}
          sx={{
            width: {
              xl: "600px",
              lg: "600px",
              md: "500px",
              sm: "500px",
              xs: "300px",
            },
          }}
        >
          <Stack
            justifyContent="space-between"
            alignItems="center"
            // sx={{ bgcolor: "yellow" }}
            direction={{ xl: "row", lg: "row", md: "row", sm: "row", xs: "column-reverse" }}
            spacing={{ xl: 3, lg: 3, md: 2, sm: 2, xs: 1 }}
          >
            <Stack direction="row" justifyContent="center" spacing={0.5} alignItems="center">
              <Stack
                direction={{ xs: "column-reverse", sm: "row" }}
                justifyContent="center"
                alignItems="center"
                spacing={{ xs: 1, sm: 2 }}
              >
                <Typography textAlign="center" sx={{ width: "100px" }} variant="body2">
                  {matches?.firstTeam?.name}
                </Typography>
                <Flag flag={matches?.firstTeam?.countryFlag} />
              </Stack>
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={1}
                sx={{
                  width: { xs: "40px", sm: "50px" },
                  height: { xs: "40px", sm: "40px" },
                  // border: "1px solid  white",
                }}
              >
                <img
                  src="/static/images/football.png"
                  alt="football"
                  style={{ width: "18px", height: "18px" }}
                />
                <Typography variant="body2">{matches?.firstTeamGoals}</Typography>
              </Stack>
            </Stack>
          </Stack>

          <Typography variant="body2">Vs</Typography>
          <Stack
            justifyContent="space-between"
            alignItems="center"
            // sx={{ bgcolor: "yellow" }}
            direction={{ xl: "row", lg: "row", md: "row", sm: "row", xs: "column-reverse" }}
            spacing={{ xl: 3, lg: 3, md: 2, sm: 2, xs: 1 }}
          >
            <Stack direction="row" justifyContent="center" spacing={0.5} alignItems="center">
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={1}
                sx={{
                  width: { xs: "40px", sm: "50px" },
                  height: { xs: "40px", sm: "40px" },
                  // border: "1px solid  white",
                }}
              >
                <Typography variant="body2">{matches?.secondTeamGoals}</Typography>
                <img
                  src="/static/images/football.png"
                  alt="football"
                  style={{ width: "18px", height: "18px" }}
                />
              </Stack>
              <Stack
                direction={{ xs: "column", sm: "row" }}
                justifyContent="center"
                alignItems="center"
                spacing={{ xs: 1, sm: 2 }}
              >
                <Flag flag={matches?.secondTeam?.countryFlag} />
                <Typography textAlign="center" sx={{ width: "100px" }} variant="body2">
                  {matches?.secondTeam?.name}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
}
