import Footer from "./components/Footer";
import Main from "./Main";

function App() {
  return (
    <div className="App">
      <Main />
      {/* <Footer /> */}
    </div>
  );
}

export default App;
