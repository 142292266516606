import { Box, Button, Stack, Typography } from "@mui/material";
import React from "react";
import moment from "moment";
import { Icon } from "@iconify/react";
import Flag from "./Flag";

export default function PredictMatchBox({ matches, predict }) {
  return (
    <Box marginTop={3} sx={{ width: { lg: "900px", md: "800px", sm: "590px", xs: "375px" } }}>
      <Stack
        sx={{
          background: "linear-gradient(180deg, #1524E0 0%, #2D5AF1 51.04%, #0006D8 100%)",
          borderRadius: "14.5347px 14.5347px 0px 0px",
          color: "white",
        }}
        direction="row"
        justifyContent="center"
        alignItems="center"
        padding={1} spacing={3}
      >
        <Typography>{matches?.matchType?.replaceAll("_"," ")}</Typography>
       <Typography> {moment(matches.matchDate).format("ddd, DD MMM h:mm a")}</Typography>
      </Stack>
      <Stack
        direction={{ xl: "row", lg: "row", md: "row", sm: "column", xs: "column" }}
        paddingY={2}
        paddingX={2}
        spacing={3}
        justifyContent="center"
        alignItems="center"
        sx={{
          background: " rgba(43, 85, 239, 0.15)",
          borderRadius: " 0px 0px 14.5347px 14.5347px ",
          color: "white",
          width: "100%",
        }}
      >
        <Stack
          direction="row"
          justifyContent={{
            xs: "center",
            sm: "space-between",
            md: "space-between",
            lg: "space-between",
          }}
          alignItems="center"
          spacing={{ xl: 3, lg: 3, md: 2, sm: 2, xs: 4 }}
          sx={{
            width: { xl: "500px", lg: "500px", md: "500px", sm: "500px", xs: "300px" },
          }}
        >
          <Stack
            justifyContent="space-between"
            alignItems="center"
            // sx={{ bgcolor: "yellow" }}
            direction={{ xl: "row", lg: "row", md: "row", sm: "row", xs: "column-reverse" }}
            spacing={{ xl: 3, lg: 3, md: 2, sm: 2, xs: 1 }}
          >
            <Typography textAlign="center" sx={{ width: "100px" }} variant="body2">
              {matches?.firstTeam?.name}
            </Typography>
            <Flag flag={matches?.firstTeam?.countryFlag} />
          </Stack>

          <Typography variant="body2">Vs</Typography>
          <Stack
            justifyContent="space-between"
            alignItems="center"
            direction={{ xl: "row", lg: "row", md: "row", sm: "row", xs: "column" }}
            spacing={{ xl: 3, lg: 3, md: 2, sm: 2, xs: 1 }}
          >
            <Flag flag={matches?.secondTeam?.countryFlag} />
            <Typography textAlign="center" sx={{ width: "100px" }} variant="body2">
              {matches?.secondTeam?.name}
            </Typography>
          </Stack>
        </Stack>
        <Stack direction="row" justifyContent={"center"} alignItems="center" paddingX={7}>
          {matches?.pollingStatus === "STARTED" && (
            <Button
              size="small"
              variant="contained"
              color="success"
              onClick={(e) => predict(e, matches)}
              sx={{
                width: "150px",
                background:
                  "linear-gradient(180deg, rgba(3, 180, 57, 0.9) 0%, rgba(0, 205, 34, 0.9) 48.96%, rgba(3, 176, 61, 0.9) 100%)",
              }}
            >
              Predict Now
            </Button>
          )}
          {matches?.pollingStatus !== "STARTED" && (
            <Button
              size="small"
              variant="contained"
              color="success"
              sx={{
                pointerEvents: "none",
                width: "150px",
                background:
                  "linear-gradient(180deg, rgba(3, 180, 57, 0.9) 0%, rgba(0, 205, 34, 0.9) 48.96%, rgba(3, 176, 61, 0.9) 100%)",
              }}
              startIcon={<Icon icon="clarity:lock-solid" />}
            >
              Predict Now
            </Button>
          )}
        </Stack>
      </Stack>
    </Box>
  );
}
