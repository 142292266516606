import { red } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

// A custom theme for this app
// const theme = createTheme({
//   palette: {
//     primary: {
//       main: "#556cd6",
//     },
//     secondary: {
//       main: "#19857b",
//     },
//     error: {
//       main: red.A400,
//     },
//   },
// });

const theme = createTheme();

theme.typography.body1 = {
  fontSize: "1rem",
  fontWeight: "400",

  [theme.breakpoints.down("md")]: {
    fontSize: "0.9rem",
    fontWeight: "400",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.8rem",
    fontWeight: "400",
  },
};
theme.typography.body2 = {
  fontSize: "1rem",
  fontWeight: "600",

  [theme.breakpoints.down("md")]: {
    fontSize: "0.9rem",
    fontWeight: "600",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.8rem",
    fontWeight: "550",
  },
};
theme.typography.subtitle1 = {
  fontSize: "0.8rem",
  fontWeight: "400",

  [theme.breakpoints.down("md")]: {
    fontSize: "0.8rem",
    fontWeight: "400",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.65rem",
    fontWeight: "400",
  },
};
theme.typography.caption = {
  fontSize: "0.8rem",
  fontWeight: "300",

  [theme.breakpoints.down("md")]: {
    fontSize: "0.7rem",
    fontWeight: "300",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.65rem",
    fontWeight: "200",
  },
};

theme.typography.button = {
  fontSize: "1rem",
  fontWeight: "600",

  [theme.breakpoints.down("md")]: {
    fontSize: "0.8rem",
    fontWeight: "600",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.8rem",
    fontWeight: "500",
  },
};

export default theme;
