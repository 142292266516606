import { Box, Card } from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";

const StyledProductImg = styled("img")({
  top: 0,
  width: "100%",
  height: "100%",
  objectFit: "cover",
  position: "absolute",
});

export default function Flag({ flag }) {
  return (
    <>
      {" "}
      <Card sx={{ height: "50px", width: "75px" }}>
        <Box sx={{ position: "relative", height: "50px", width: "75px" }}>
          <StyledProductImg alt="flag" src={flag} />
        </Box>
      </Card>
    </>
  );
}
