import { Box, Stack, Typography } from "@mui/material";
import React from "react";

export default function WinnerCard({ winner }) {
  return (
    <Stack sx={{ width: "260.69px" }} marginX={3} paddingX={3}>
      <Stack
        sx={{
          background: "linear-gradient(180deg, #1729E1 0%, #2A55EF 49.48%, #0C2CE7 100%)",
          width: "100%",
          height: "39.57px",
          borderRadius: "11.63px",
          zIndex: "999",
        }}
        justifyContent="center"
        alignItems="center"
        color="white"
      >
        <Typography variant="body2">
          {`${winner.matchDetails.firstTeam} vs ${winner.matchDetails.secondTeam}`}
        </Typography>
      </Stack>
      <Box
        sx={{
          bgcolor: "white",
          borderRadius: "32px",
          height: "170px",
          marginTop: "-25px",
          width: "100%",
        }}
      >
        <Stack
          sx={{ paddingTop: "35px", width: "100%" }}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{
              borderRadius: "50%",
              height: "66px",
              width: "66px",
              textAlign: "center",
              backgroundImage: 'url("/static/images/position.png")',
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              margin: 0,
            }}
            mb={1}
          >
            <Typography
              variant="body2"
              sx={{
                fontSize: "30px",
                color: "  #FBE88E ",
              }}
            >
              {winner.position}
            </Typography>
          </Stack>
          <Stack
            sx={{ width: "180px" }}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Typography variant="body2">{winner.userDetails?.fullname?.toUpperCase()}</Typography>
          </Stack>
          <Stack sx={{ width: "100%" }} direction="row" justifyContent="center" alignItems="center">
            <Typography variant="body2"> {winner.userDetails?.mobileNumber}</Typography>
          </Stack>
        </Stack>
      </Box>
    </Stack>
  );
}
