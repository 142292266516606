import { Box, Stack, Typography } from "@mui/material";
import React from "react";

export default function WinnerCard({ winner }) {
  // console.log("winner card", winner.position);
  return (
    <Stack sx={{ width: "160.69px" }} marginX={1} paddingX={1}>
      <Box
        sx={{
          background: "linear-gradient(180deg, #1729E1 0%, #2A55EF 49.48%, #0C2CE7 100%)",
          width: "100%",
          height: "22.57px",
          borderRadius: "6.63px",
          zIndex: "999",
          color: "white",
        }}
      >
        {winner.position}
      </Box>
      <Box
        sx={{
          bgcolor: "#ffffff",
          borderRadius: "20px",
          height: "65px",
          marginTop: "-15px",
          marginBottom: "5px",
          width: "100%",
          boxShadow: " rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px",
        }}
      >
        <Stack
          sx={{ paddingTop: "20px", width: "100%" }}
          direction="column"
          alignItems="center"
          justifyContent="center"
          spacing={0.2}
        >
          {/* <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{
              borderRadius: "50%",
              bgcolor: "#000B1A",
              height: "36px",
              width: "36px",
              textAlign: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: "20px",
                color: "  #FBE88E ",
                fontWeight: "bold",
              }}
            >
              {winner.position}
            </Typography>
          </Stack> */}
          <Stack
            sx={{ width: "180px" }}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            {" "}
            <Typography
              noWrap
              sx={{
                fontSize: "14px",
                fontWeight: "bold",
                width: "135px",
                textOverflow: "ellipsis",
              }}
            >
              {winner.userDetails?.fullname?.toUpperCase()}
            </Typography>
          </Stack>
          <Stack sx={{ width: "100%" }} direction="row" justifyContent="center" alignItems="center">
            <Typography sx={{ fontSize: "14px", fontWeight: "bold" }}>
              {" "}
              {winner.userDetails?.mobileNumber}
            </Typography>
          </Stack>
        </Stack>
      </Box>
    </Stack>
  );
}
