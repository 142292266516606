import {
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import { Icon } from "@iconify/react";
import Flag from "./Flag";
import CarouselWinner from "./SmallCarousel";
import SmallFlag from "./SmallFlag";

export default function PreviousMatchCard({ match }) {
  const [winners, setWinners] = useState([]);
  const [winnersLoading, setWinnersLoading] = useState(true);
  const [matchId, setMatchId] = useState(match._id);
  const [widthPercentage, setWidthPercentage] = useState(0);

  const xlsize = useMediaQuery("(min-width:1500px)");
  const lgsize = useMediaQuery("(min-width:1200px )");
  const mdsize = useMediaQuery("(min-width:900px )");
  const smsize = useMediaQuery("(min-width:600px )");
  const xssize = useMediaQuery("(max-width:600px)");
  // console.log("previous match");

  const getUpcomingMatch = () => {
    setWinnersLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/draw/user/match/all`, {
        params: { matchId: match._id },
      })
      .then((res) => {
        // console.log("winners", res.data.data.records);
        setWinners(res.data.data.records);
        setWinnersLoading(false);
      })
      .catch((err) => {
        // console.log(err);
      });
  };
  useEffect(() => {
    getUpcomingMatch();
  }, [matchId]);

  useEffect(() => {
    // console.log(xssize, smsize, mdsize, lgsize, xlsize);
    if (xssize) {
      setWidthPercentage(70);
    }
    if (smsize && !mdsize) {
      setWidthPercentage(40);
    }
    if (mdsize && !lgsize) {
      setWidthPercentage(26);
    }
    if (lgsize && !xlsize) {
      setWidthPercentage(23);
    }
    if (xlsize) {
      setWidthPercentage(20);
    }
  }, [xssize, smsize, mdsize, lgsize, xlsize]);

  return (
    <Box sx={{ width: { lg: "900px", md: "800px", sm: "590px", xs: "375px" } }}>
      <Stack
        sx={{
          background: "linear-gradient(180deg, #1728E1 0%, #2B56F0 50.52%, #0D1FDF 100%)",
          color: "white",
          borderRadius: "15px 15px 0px 0px",
        }}
        direction="row"
        justifyContent="center"
        alignItems="center"
        padding={1}
      >
        {moment(match.matchDate).format("ddd, DD MMM h:mm a")}
      </Stack>
      <Box sx={{ bgcolor: "white", borderRadius: " 0px 0px 15px 15px " }} pt={1}>
        <Stack direction={"column"} paddingY={3} paddingX={5} spacing={4}>
          <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
            <Stack
              sx={{
                ...(!xssize && { width: "45%" }),
                ...(xssize && { width: "35%" }),
              }}
              justifyContent={"center"}
              alignItems="center"
              direction={{ lg: "row", md: "row", sm: "row", xs: "column-reverse" }}
              spacing={2}
            >
              <Typography variant="body2">{match.firstTeam.name}</Typography>
              <Stack
                justifyContent="center"
                alignItems="center"
                direction={"row"}
                spacing={{ lg: 3, md: 2, sm: 2, xs: 1 }}
              >
                <Card style={{ border: "solid 1px black" }}>
                  {!xssize && <Flag flag={match.firstTeam.countryFlag} />}
                  {xssize && <SmallFlag flag={match.firstTeam.countryFlag} />}
                </Card>
                <Stack
                  sx={{
                    ...(!xssize && {
                      width: "60px",
                      height: "50px",
                    }),
                    ...(xssize && {
                      width: "40px",
                      height: "30px",
                    }),
                    border: "solid 1px black",
                    borderRadius: "5px",
                  }}
                  justifyContent="center"
                  alignItems="center"
                  direction={"row"}
                  spacing={0.5}
                >
                  <Icon icon="ion:football-sharp" />
                  <Typography sx={{ fontWeight: "bold" }}>{match.firstTeamGoals}</Typography>
                </Stack>
              </Stack>
            </Stack>

            <Typography variant="body2">Vs</Typography>
            <Stack
              sx={{ ...(!xssize && { width: "45%" }), ...(xssize && { width: "35%" }) }}
              justifyContent="center"
              alignItems="center"
              direction={{ lg: "row", md: "row", sm: "row", xs: "column" }}
              spacing={2}
            >
              {" "}
              <Stack
                justifyContent="center"
                alignItems="center"
                direction={"row"}
                spacing={{ lg: 3, md: 2, sm: 2, xs: 1 }}
              >
                <Stack
                  sx={{
                    ...(!xssize && {
                      width: "60px",
                      height: "50px",
                    }),
                    ...(xssize && {
                      width: "40px",
                      height: "30px",
                    }),
                    border: "solid 1px black",
                    borderRadius: "5px",
                  }}
                  justifyContent="center"
                  alignItems="center"
                  direction={"row"}
                  spacing={0.5}
                >
                  <Icon icon="ion:football-sharp" />
                  <Typography sx={{ fontWeight: "bold" }}>{match.secondTeamGoals}</Typography>
                </Stack>{" "}
                <Card style={{ border: "solid 1px black" }}>
                  {!xssize && <Flag flag={match.secondTeam.countryFlag} />}
                  {xssize && <SmallFlag flag={match.secondTeam.countryFlag} />}
                </Card>
              </Stack>
              <Typography variant="body2">{match.secondTeam.name}</Typography>
            </Stack>
          </Stack>

          <Stack direction="row" justifyContent="center" alignItems="center" sx={{ width: "100%" }}>
            <Typography variant="body2"> {match?.matchType?.replaceAll("_"," ")}</Typography>
          </Stack>

          <Stack direction="row" justifyContent="center" alignItems="center" sx={{ width: "100%" }}>
            <Box
              sx={{
                width: "200px",
                textAlign: "center",
                background: "#D9D9D9",
                borderRadius: "30px",
              }}
              padding={0.5}
            >
              <Typography sx={{ fontWeight: "bold" }}>Winners List</Typography>
            </Box>
          </Stack>
          {winnersLoading ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "50vh",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{ width: "100%" }}
            >
              <Box sx={{ overflow: "hidden" }}>
                <CarouselWinner winners={winners} width={widthPercentage} />
              </Box>
            </Stack>
          )}
        </Stack>
      </Box>
    </Box>
  );
}
