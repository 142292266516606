import {
  CircularProgress,
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  Box,
  TableRow,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";

export default function LeaderBoardTable({ matchType, head }) {
  const [pages, setPages] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const size = 5;
  const [options, setOptions] = useState({ size, page: 0, matchType });
  const [leaderBoard, setLeaderBoard] = useState([]);
  const [leaderBoardCount, setLeaderBoardCount] = useState(0);
  const [leaderboardLoading, setLeaderboardLoading] = useState(true);

  const getLeaderBoardMatch = (options) => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/poll/user/leadershipBoard/all`, {
        params: options,
      })
      .then((res) => {
        // console.log( res.data.data.records);
        setLeaderBoardCount(res.data.data.maxRecords);
        setLeaderBoard(res.data.data.records);
        setIsLoading(false);
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const handlePageChange = (event, newPage) => {
    const temp = { ...options, page: newPage };
    setPages(newPage);
    setOptions(temp);
    setIsLoading(true);
    getLeaderBoardMatch(temp);
  };

  useEffect(() => {
    getLeaderBoardMatch(options);
  }, []);

  return (
    <Container sx={{ paddingY: { xs: 2, sm: 3, md: 4 }, paddingX: {xs:0.5,  sm: 2 } }}>
      {isLoading ? (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "500px",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          {leaderBoardCount ? (
            <Stack justifyContent="center" alignItems="center">
              <Box sx={{ width: { xs: "100%", sm: "450px", md: "600px" } }}>
                <Stack
                  sx={{
                    background: "linear-gradient(180deg, #1728E1 0%, #2B56F0 50.52%, #0D1FDF 100%)",
                    color: "white",
                    borderRadius: "15px 15px 0px 0px",
                  }}
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  padding={1}
                >
                  {/* {matchType.replaceAll("_", " ")} */}
                  <Typography variant="h6">{head}</Typography>
                </Stack>
                <Stack
                  direction={"column"}
                  paddingY={2}
                  paddingX={1}
                  spacing={3.5}
                  sx={{
                    bgcolor: "white",
                    borderRadius: "0px 0px 15px 15px",
                    width: "100%",
                  }}
                  justifyContent="center"
                  alignItems="center"
                >
                  <TableContainer
                    sx={{
                      maxWidth: { xs: "100%", sm: "440px", md: "500px" },
                      border: "solid white 1px",
                      marginY: 2,
                    }}
                  >
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ color: "black" }}>Rank</TableCell>
                          <TableCell sx={{ color: "black" }}>Name</TableCell>
                          <TableCell sx={{ color: "black" }}>
                            Points
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {leaderBoard?.map((item, id) => (
                          <TableRow key={id}>
                            <TableCell sx={{ color: "black" }}>{item.rank}</TableCell>
                            <TableCell sx={{ color: "black" }}>
                              <Stack direction="row" alignItems="center" spacing={1}>
                                <img src="/static/images/user.png" alt="logo" style={{height:"30px", width:"30px"}} />
                                <Typography variant="subtitle1">
                                  {item?.fullname?.toUpperCase()}
                                </Typography>
                              </Stack>
                            </TableCell>

                            <TableCell
                              sx={{ width: { xs: "80px", sm: "110px" }, color: "black" }}
                            >
                              <Typography variant="subtitle1">{item.totalPoints}</Typography>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Stack sx={{ width: "100%", maxWidth: "450px" }}>
                    <TablePagination
                      rowsPerPageOptions={[]}
                      component={"div"}
                      sx={{
                        color: "black",
                        marginTop: "-20px",
                        "& .MuiTablePagination-displayedRows": { fontSize: "12px" },
                      }}
                      count={leaderBoardCount}
                      onPageChange={handlePageChange}
                      rowsPerPage={size}
                      page={pages}
                      size="small"
                    />
                  </Stack>
                </Stack>
              </Box>
            </Stack>
          ) : (
            <>
              <Stack />
            </>
          )}
        </>
      )}
    </Container>
  );
}
