import { Typography } from "@mui/material";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import React from "react";
import { Carousel } from "react-responsive-carousel";
import WinnerCard from "./SmallWinnerCard";

export default ({ winners, width }) => {
  return (
    <Carousel
      autoPlay={winners.length > 3 || width === 70}
      infiniteLoop={winners.length > 3 || width === 70}
      centerSlidePercentage={width * 0.95}
      interval={2000}
      centerMode
      showIndicators={false}
      showArrows={false}
      showThumbs={false}
      showStatus={false}
      swipeable
    >
      {winners.map((item, key) => {
        return <WinnerCard key={key} winner={item} />;
      })}
    </Carousel>
  );
};
