import { Box, CircularProgress, Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import axios from "axios";
import { Icon } from "@iconify/react";
import UpcomingMatch from "./upcomingMatchCard";
import PreviousMatchCard from "./PreviousMatchCard";
import LeaderBoardTable from "./LeaderBoardTable";

export default function Matches({ predict }) {
  const [upcoming, setUpcomingMatchs] = useState([]);
  const [upcomingMatchLoading, setUpcomingMatchLoading] = useState(true);
  const [previous, setPreviousMatchs] = useState([]);
  const [previousMatchLoading, setPreviousMatchLoading] = useState(true);
  const [showUpcoming, setShowUpcoming] = useState(true);
  const [showPrevious, setShowPrevious] = useState(false);
  const [showLeaderboard, setShowLeaderboard] = useState(false);

  const xssize = useMediaQuery("(max-width:600px)");

  // console.log("re rendering matches");

  const getUpcomingMatch = () => {
    setUpcomingMatchLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/match/user/upcoming`)
      .then((res) => {
        // console.log("upcoming match", res);
        setUpcomingMatchs(res.data.data.records);
        setUpcomingMatchLoading(false);
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const getPreviousMatch = () => {
    setPreviousMatchLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/match/user/past`)
      .then((res) => {
        // console.log("Previous match", res.data.data.records);
        setPreviousMatchs(res.data.data.records);
        setPreviousMatchLoading(false);
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const showUpcomingMatches = () => {
    setShowUpcoming(true);
    setShowPrevious(false);
    setShowLeaderboard(false);
  };
  const showPreviousMatches = () => {
    setShowUpcoming(false);
    setShowPrevious(true);
    setShowLeaderboard(false);
    getPreviousMatch();
  };
  const showLeaderboardTable = () => {
    setShowLeaderboard(true);
    setShowUpcoming(false);
    setShowPrevious(false);
  };

  useEffect(() => {
    getUpcomingMatch();
  }, [showUpcoming]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={{ xs: 0.5, sm: 2 }}
        >
          <Box
            sx={{
              ...(showUpcoming && {
                background: "linear-gradient(180deg, #2950EE 0%, #2B55EF 45.83%, #1727E1 100%)",
              }),
              ...(!showUpcoming && {
                background: "rgba(33, 62, 232, 0.4)",
              }),
              paddingX: { xs: "10px", sm: "20px", md: "30px", lg: "41px" },
              paddingY: "9px",
              color: "white",
              borderRadius: "8px 8px 0px 0px",
              cursor: "pointer",
            }}
            onClick={showUpcomingMatches}
          >
            <Typography variant="body2"> {xssize ? "Upcoming" : "Upcoming Match"}</Typography>
          </Box>
          <Box
            sx={{
              ...(showLeaderboard && {
                background: "linear-gradient(180deg, #2950EE 0%, #2B55EF 45.83%, #1727E1 100%)",
              }),
              ...(!showLeaderboard && {
                background: "rgba(33, 62, 232, 0.4)",
              }),
              paddingX: { xs: "10px", sm: "20px", md: "30px", lg: "41px" },
              paddingY: "9px",
              color: "white",
              borderRadius: "8px 8px 0px 0px",
              cursor: "pointer",
            }}
            onClick={showLeaderboardTable}
          >
            <Typography variant="body2"> Leaderboard</Typography>
          </Box>
          <Box
            sx={{
              ...(showPrevious && {
                background: "linear-gradient(180deg, #2950EE 0%, #2B55EF 45.83%, #1727E1 100%)",
              }),
              ...(!showPrevious && {
                background: "rgba(33, 62, 232, 0.4)",
              }),
              paddingX: { xs: "10px", sm: "20px", md: "30px", lg: "41px" },
              paddingY: "9px",
              color: "white",
              borderRadius: "8px 8px 0px 0px",
              cursor: "pointer",
            }}
            onClick={showPreviousMatches}
          >
            <Typography variant="body2"> {xssize ? "Previous" : "Previous Match"}</Typography>
          </Box>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Stack direction="row" justifyContent="center" alignItems="center">
          <Grid
            container
            sx={{
              width: { xs: "96%", sm: "90%", md: "85%", lg: "80%" },
              maxWidth: "1100px",
            }}
          >
            <Grid item xs={12}>
              <Box
                sx={{
                  width: "100%",
                  height: "50px",
                  background: "linear-gradient(180deg, #1524E0 0%, #2D5AF1 51.04%, #0006D8 100%)",
                  border: " 0.967552px solid #00B2FF",
                  borderRadius: "35.3156px 35.3156px 0px 0px",
                }}
              />
            </Grid>
            {showUpcoming && (
              <Grid
                item
                xs={12}
                paddingY={4}
                paddingX={{ xl: 5, lg: 5, md: 4, sm: 3, xs: 1 }}
                sx={{
                  background: "rgba(28, 58, 231, 0.15)",
                  border: " 0.967552px solid #00B2FF",
                  borderTop: 0,
                }}
              >
                <Box
                  className="scrollableBox"
                  sx={{ maxHeight: "1200px", overflowY: "scroll", minHeight: "1200px" }}
                >
                  {upcomingMatchLoading ? (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "50vh",
                      }}
                    >
                      <CircularProgress />
                    </div>
                  ) : (
                    <Grid
                      container
                      direction={"row"}
                      justifyContent="center"
                      rowSpacing={5}
                      spacing={3}
                    >
                      {upcoming.map((item, key) => {
                        return (
                          <>
                            <Grid item xs={12}>
                              <Stack
                                sx={{ width: "100%" }}
                                justifyContent="center"
                                alignItems="center"
                              >
                                <Stack
                                  justifyContent={"center"}
                                  alignItems="center"
                                  sx={{
                                    bgcolor: "white",
                                    width: "153px",
                                    height: "40px",
                                    borderRadius: "30px",
                                  }}
                                  textAlign="center"
                                >
                                  <Stack
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                    spacing={1}
                                  >
                                    <Typography variant="body2" sx={{ fontWeight: "800" }}>
                                      <Icon icon="fontisto:date" />
                                    </Typography>{" "}
                                    <Typography variant="body2" sx={{ fontWeight: "800" }}>
                                      {item.date}
                                    </Typography>
                                  </Stack>
                                </Stack>
                              </Stack>
                            </Grid>
                            {item.plays.map((_a, id) => {
                              return (
                                <Grid item xs={12} md={6}>
                                  <Stack
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{ width: "100%" }}
                                  >
                                    <UpcomingMatch match={_a} predict={predict} />
                                  </Stack>
                                </Grid>
                              );
                            })}
                          </>
                        );
                      })}
                    </Grid>
                  )}
                </Box>
              </Grid>
            )}
            {showPrevious && (
              <Grid
                item
                xs={12}
                paddingY={4}
                paddingX={{ xl: 5, lg: 5, md: 4, sm: 3, xs: 1 }}
                sx={{
                  background: "rgba(28, 58, 231, 0.15)",
                  border: " 0.967552px solid #00B2FF",
                  borderTop: 0,
                }}
              >
                <Box
                  className="scrollableBox"
                  sx={{ maxHeight: "1200px", overflowY: "scroll", minHeight: "1200px" }}
                >
                  {previousMatchLoading ? (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "50vh",
                      }}
                    >
                      <CircularProgress />
                    </div>
                  ) : (
                    <Grid
                      container
                      direction={"row"}
                      justifyContent="center"
                      rowSpacing={5}
                      spacing={3}
                    >
                      {previous?.map((item, key) => {
                        return (
                          <Grid key={key} item xs={12}>
                            <Stack
                              direction="row"
                              justifyContent="center"
                              alignItems="center"
                              sx={{ width: "100%" }}
                            >
                              <PreviousMatchCard match={item} />
                            </Stack>
                          </Grid>
                        );
                      })}
                    </Grid>
                  )}
                </Box>
              </Grid>
            )}

            {showLeaderboard && (
              <Grid
                item
                xs={12}
                paddingY={4}
                paddingX={{ xl: 5, lg: 5, md: 4, sm: 3, xs: 1 }}
                sx={{
                  background: "rgba(28, 58, 231, 0.15)",
                  border: " 0.967552px solid #00B2FF",
                  borderTop: 0,
                }}
              >
                <Box
                  className="scrollableBox"
                  sx={{ maxHeight: "1200px", overflowY: "scroll", minHeight: "1200px" }}
                >
                  <Stack>
                    <LeaderBoardTable
                      matchType={["FINAL", "THIRD_PLACE", "SEMI_FINAL", "QUARTER", "PRE_QUARTER"]} head={"Knock-Out Level"} 
                    />
                    {/* <LeaderBoardTable matchType={["GROUP"]}  head={"Group Level"} /> */}
                  </Stack>
                </Box>
              </Grid>
            )}
          </Grid>
        </Stack>
      </Grid>
    </Grid>
  );
}
